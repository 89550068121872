<div class="fondo">
    <div style="text-align: -webkit-center;">
        <div id="buscador" class="buscador-container" [style.background-color]="data.background_color">
            <h1 class="titulo-buscador quicksand-medium" [style.color]="data.color_title">{{data.title}} </h1>
            <h5 class="subtitulo-buscador quicksand-medium" [style.color]="data.color_subtitle">{{data.subtitle}}
            </h5>

            <form [formGroup]="buscador" class="form-group" id="busqueda">

                <div style="display: flex;
            flex-wrap: wrap; width: 100%; justify-content: space-between;" class="container-responsive" #busqueda>
                <div class="input-container">
                    <input #filtroInput autofocus type="text" formControlName='palabras' 

                    (keyup)="(filtroInput.value.length >= 5 || filtroInput.value.length==0)? filtrar(null) : null"
                        class="form-control-c " placeholder="Palabras clave"
                        style="border-radius: 0px !important;     padding-left: 10px;">
                        <img class="buscar" src="../../../../../assets/img/buscar-gris.png" alt="">

                </div>
                <div class="input-container">
             
                    <app-custom-select  [colorArrow]="'gris'"  [options]="niveles" placeholder="Elegí el nivel" placeholderAll="Todos los niveles"
                        (selectionChange)="updateFilter('nivel', $event)" [id]="'select-nivel'">
                    </app-custom-select>
                </div>
                <div class="input-container" *ngIf="!sede">
         
                    <app-custom-select  [colorArrow]="'gris'"  [options]="regiones" placeholder="Elegí la sede" placeholderAll="Todas las sedes"
                        (selectionChange)="updateFilter('sede', $event)" [id]="'select-sede'">
                    </app-custom-select>

                </div>
                <div class="input-container">
                 
                    <app-custom-select  [colorArrow]="'gris'"  [options]="areas" placeholder="Elegí el área" placeholderAll="Todas las áreas"
                        (selectionChange)="updateFilter('area', $event)" [id]="'select-area'">
                    </app-custom-select>
                </div>
                <div class="input-container" >
                  
                    <app-custom-select  [colorArrow]="'gris'"  [options]="modalidades" placeholder="Modalidad" placeholderAll="Todas las modalidades"
                        (selectionChange)="updateFilter('modalidad', $event)" [id]="'select-modalidad'">
                    </app-custom-select>
                </div>



                </div>
                <!-- <div class='text-center'>
                <a (click)='filtrar()' class="btn btn-primary ml-2">Buscar</a>
    
            </div> -->


            </form>
        </div>

        <div *ngIf="sede" routerLink="/es/posgrados" class="breadcump">
            <span class="nunito-medium"> Posgrados | <span class="nunito-bold">  {{sede.title}}</span></span>
        </div>
        <div [ngClass]="{
        'resultados-principal': !sede, 
        'resultados-secundario': sede
    }" class="resultados card p-2" id="resultadosSwipe" #resultadosSwipe>
            <ul *ngIf='!spinner' style="padding-left: 0px !important;">
                <!-- <li class='mt-3 fs-21' *ngFor='let carrera of resultados'><a [href]="language +carrera.link"> {{carrera.nombre}} | {{carrera.modalidad}} </a></li> -->
                <div class="sede-container">
                    <ng-container *ngFor='let carrera of resultados'>
                        <app-card-overlay [carrera]="carrera" [posgrado]="true"></app-card-overlay>
                    </ng-container>
                </div>


            </ul>
            <div class="d-flex justify-content-center" *ngIf='spinner && filtro'>
                <div class="spinner-grow loading" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow loading" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow loading" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <div class="paginacion mt-3" *ngIf='!spinner && resultados.length > 0  && pages.length >1'>
                <div *ngFor="let page of pages" class="number" [ngClass]='(numberActive == page) ? "active" : ""'
                    (click)='cambiarPagina(page, false, true)'>{{page}}</div>

            </div>


            <div *ngIf=' resultados.length == 0 && !destacadas && !spinner && filtro' class='p-3 text-center'>
                <div class="alert alert-primary text-center" role="alert">
                    Lo sentimos, no encontramos resultados. ¿Te gustaría intentar con otras palabras parecidas?
                                </div>
            </div>
        </div>
    </div>
</div>
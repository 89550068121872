import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-portada',
  templateUrl: './portada.component.html',
  styleUrls: ['./portada.component.scss']
})
export class PortadaComponent implements OnInit {
  isMob: boolean
  //busqueda: boolean
  @Input() path: string;
  @Input() pathMob: string;
  @Input() layerPath: string;
  @Input() layerPathMob: string;
  @Input() data: any;
  @Input() marginTitle: number;
  @Input() heightDesk: string;
  @Input() heightMob: string;
  @Input() area: any;
  @Input() video_path: string;
  @Input() tieneTyS: boolean;


  //borrar cuando ingreso sea dinamico
  @Input() layerPathSrc: string;
  @Input() layerPathMobSrc: string;
  //borrar cuando ingreso sea dinamico


  @ViewChild('svgContainer', { static: true }) svgContainer: ElementRef;
  svgContent: SafeHtml | null = null;
  @ViewChild('videoPlayer') videoPlayer: any;
  @ViewChild('videoPlayerMob') videoPlayerMob: any;
  hayBotones = false
  constructor(
    private renderer: Renderer2, private el: ElementRef,
    private http: HttpClient, private sanitizer: DomSanitizer) {

  }

  ngAfterViewInit() {
    setTimeout(() => {
      const element = this.el.nativeElement.querySelector('#magic');
      if (element) {
        this.renderer.addClass(element, 'twisterInDown');
      }
      this.checkScreenWidth()

    }, 100);

    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.muted = true
      this.videoPlayer.nativeElement.play();
    }
    if (this.videoPlayerMob) {
      this.videoPlayerMob.nativeElement.muted = true
      this.videoPlayerMob.nativeElement.play();

      //this.videoPlayerMob.nativeElement.pause();
      //this.videoPlayerMob.nativeElement.currentTime = 3; 


    }

    this.checkScreenWidth()

  }
  iconLink = ""


  ngOnInit(): void {
    console.log(this.area)
    if (this.area && this.area.file) {
      if (this.area.file.endsWith('svg')) {
        this.loadSvg(this.area.file);
      } else {
        this.iconLink = this.createLink(this.area.file)
      }
    } else {
      if (this.data?.cover.check_icono) {
        this.iconLink = this.createLink(this.data.cover.icon_path)
      }
      this.data.coverButton?.forEach(button => {
        if (button.estado) {
          this.hayBotones = true
        }
      })
    }
    this.checkScreenWidth()


  }

  adjustTitlePosition() {
    if (this.isMob) {
      $(".t-container").css("margin-bottom", 10 + "px");
      $(".t-container").css(" margin-left", 38 + "px");
      $(".icon-link").css('height', "30px")
      $(".overlay").css("height", "100%");

    } else {
      if (this.heightDesk) {
        $(".img-desk").css("height", this.heightDesk);
        $(".video-desk").css("height", this.heightDesk);
        $(".overlay").css("height", this.heightDesk);
      }
      if (this.marginTitle) {
        $(".t-container").css("margin-bottom", (this.marginTitle + 10) + "px");
      }
    }
  }

  createLink(item) {
    let url = environment.apiUrl + item
    return url
  }


  loadSvg(file: string): void {
    this.http.get(this.createLink(file), { responseType: 'text' }).subscribe(svg => {
      const modifiedSvg = this.changeSvgColor(svg, this.data.cover.color_subtitle);
      this.svgContent = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/svg+xml,' + encodeURIComponent(modifiedSvg));
    });
  }

  changeSvgColor(svg: string, color: string): string {
    return svg.replace(/fill: #[0-9a-fA-F]{6};/g, `fill: ${color};`);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenWidth()
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    this.isMob = (window.innerWidth <= 768);
    this.adjustTitlePosition();

  }

}
